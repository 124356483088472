<template>
  <v-select
    class="d-season-crop-selector"
    style="background: white"
    v-model="selectedCurrentSeason"
    :items="seasonCropsList"
    item-value="id"
    :item-text="(item) => item.season_name"
    single-line
    :label="$t('safra.safra')"
    target="_blank"
    color="#39af49"
    outlined
    dense
    hide-details
    return-object
  >
  </v-select>
</template>

<script>
import PermissionMixin from '@/components/Permission/PermissionMixin'
import seasonsCropsStorage from '@/services/storage/seasonsCropsStorage'
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  mixins: [PermissionMixin],

  data() {
    return {
      selectedCurrentSeason: null,
      seasonCropsList: [],
    }
  },

  mounted() {
    this.setSelectedSeasonCrop()
  },

  computed: {
    ...mapGetters('farms', ['currentFarmId']),
    ...mapGetters('seasonsCrops', ['seasonsCrops']),
    ...mapGetters('crops', ['currentCrop']),
  },

  watch: {
    currentCrop() {
      this.setSelectedSeasonCrop()
    },

    async selectedCurrentSeason() {
      this.setCurrentSeasonCrop(this.selectedCurrentSeason)

      seasonsCropsStorage(this.selectedCurrentSeason).save()

      this.fetchDiseases()

      this.fetchSeasonCropVarieties(this.currentFarmId)
      this.$emit('changed')
    },
  },

  methods: {
    ...mapActions('seasonsCrops', ['fetchDiseases']),
    ...mapActions('cultivares', ['fetchSeasonCropVarieties']),
    ...mapMutations('seasonsCrops', ['setCurrentSeasonCrop']),

    setSelectedSeasonCrop() {
      this.seasonCropsList = this.seasonsCrops.filter(
        ({ crop_id }) => crop_id == this.currentCrop?.id
      )
      const storedSeasonCrop = this.getStoredSeasonCrop(this.seasonCropsList)

      if (storedSeasonCrop) {
        this.selectedCurrentSeason = storedSeasonCrop
        return
      }

      this.selectedCurrentSeason = this.getLastSeasonCrop()
    },

    getStoredSeasonCrop(seasonCropsList) {
      const storedSeasonCrop = seasonsCropsStorage()
        .crop(this.currentCrop?.id)
        .get()

      const savedSeasonCropFound = seasonCropsList.find(
        (seasonCrop) => seasonCrop.id == storedSeasonCrop?.id
      )

      if (savedSeasonCropFound?.id) {
        return savedSeasonCropFound
      }

      return false
    },

    getLastSeasonCrop() {
      return this.seasonCropsList[this.seasonCropsList.length - 1]
    },
  },
}
</script>

<style scoped>
::v-deep .v-list-item__title {
  font-family: 'Source Sans Pro';
  font-weight: 400 !important;
  font-size: 17px !important;
}
::v-deep .v-select__selection--comma {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #5b6459 !important;
}
</style>
